import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { toast } from 'react-toastify';

import { Card, Typography, Avatar, Chip } from "@material-tailwind/react";
import Pagination from '../../../Components/Pagination';
import SearchInput from '../../../Components/SearchInput';

import { HiPencilAlt } from "react-icons/hi";
import { FaUserAltSlash } from "react-icons/fa";
import { FaUserCheck } from "react-icons/fa";
import { HiTrash } from "react-icons/hi2"

const TABLE_HEAD = ["Avatar", "Name", "Email", "Status", "Role", "Permissions", "Actions"];

export default function AdminTable({ updateIsEditable, updateTable }) {

    const [users, setUsers] = useState([]);
    const [isAltered, setIsAltered] = useState(false);
    const [userPermissions, setUsersPermissions] = useState([])
    const [currUserPermissions, setCurrUserPermissions] = useState([])
    const [editCredentials, setEditCredentials] = useState({ role: '', firstName: '', lastName: '' })
    const [isLoad, setIsLoad] = useState(false)
    const [isEditable, setIsEditable] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");

    const usersPerPage = 9;
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;

    users.sort((a, b) => {
        const nameA = a.firstName?.toLowerCase() || '';  // Fallback to empty string if undefined
        const nameB = b.firstName?.toLowerCase() || '';  
        return nameA.localeCompare(nameB);
    });

    const filteredUsers = users.filter(user =>
        user.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

    useEffect(() => {
        axios.get('/api/departments/get')
            .then(response => {
                if (response.data.message) {
                    console.log('Cannot get departments')
                } else {              
                    setUsersPermissions(response.data.result)
                }
            })
    }, []);

    useEffect(() => {
        setIsLoad(true)
        axios.get('/api/users/get-users?role=&isActive=')
            .then(response => {
                if (response.data.message) {
                    console.log("Cannot get users");
                } else {
                    setUsers(response.data.result); 
                }
                setIsLoad(false)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, [isAltered, updateTable]);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    const toggleEdit = (index) => {
        setIsEditable(prevState => (prevState === index ? false : index)); 
        updateIsEditable(prevState => !prevState);
    };
    // USER HANDLERS
    const handleEditUser = (id, role, firstName, lastName) => {

        const updatedCredentials = {
            role: editCredentials.role || role || '',
            firstName: editCredentials.firstName || firstName || '',
            lastName: editCredentials.lastName || lastName || ''
        };
    
        console.log("editCredentials: ", updatedCredentials);
    
        axios.put(`/api/users/update-user/${id}`, updatedCredentials)
            .then(response => {
                if (response.data.message) {
                    toast.success(`${response.data.message}`);
                    setEditCredentials({ role: '',  firstName:  '', lastName: '' });
                    toggleEdit(false);
                    setIsAltered(prevState => !prevState);
                } else {    
                    toast.error(`${response.data.message}`);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                const errorMessage = error.response?.data?.message || 'An error occurred during add.';
                toast.error(errorMessage);
            });
    };

    const handleUserPermissions = (id, department, departmentId, accessibility) => {
        console.log('department: ', department)
        console.log('departmentId: ', departmentId)
        const addPermissions = [
            {
                departmentId: departmentId,
                department: department,
                accessibility: true
            }
        ];
                                                    
        axios.get(`/api/users/get-user/${id}`)
            .then((response) => {
                const currUserPermissions = response.data.permissions.map(permission => permission.department);
                // Check if department already exists
                if (currUserPermissions.includes(department)) {
                    toast.info(`Department "${department}" already exists for user ${id}. No action taken.`);
                    return;
                } else {

                    console.log("Added department: ", addPermissions);
    
                    axios.put(`/api/users/${id}/permissions`, addPermissions)
                        .then(response => {
                            if (response.data.message) {
                                console.log("Updated department: ", addPermissions);
                                setIsAltered(prevState => !prevState);
                            } else {
                                toast.error(`Update failed: ${response.data.message}`);
                            }
                        })
                        .catch(error => {
                            console.error('Error:', error);
                            const errorMessage = error.response?.data?.message || 'An error occurred during the update.';
                            toast.error(errorMessage);
                        })
                }
            })
            .catch((error) => {
                console.error('Error fetching user data:', error);
            });
    };
    
    const handleToggleUserStatus = (isActive, id, firstName, lastName) => {
        const updatedUserData = {
            isActive: !isActive 
        };
    
        axios.put(`/api/users/update-user/${id}`, updatedUserData)
            .then(response => {
                if (response.data.message) {
                    toast.info(`User ${firstName}: ${isActive === true ? 'Disabled' : 'Activated'}`);
                    console.log("updated: ", updatedUserData)
                    setIsAltered(prevState => !prevState);
                } else {
                    toast.error(`${response.data.message}`);
                }
            })
            .catch(error => {
                console.error('Error:', error);
                const errorMessage = error.response?.data?.message || 'An error occurred during add.';
                toast.error(errorMessage);
            });
    };
    
    const handleDeleteUser = (id, firstName, lastName) => {
        const isConfirmed = window.confirm(`Are you sure you want to delete the user ${firstName} ${lastName}?`);

        if (isConfirmed) {
            axios.delete(`/api/users/delete-user/${id}`)
                .then(response => {
                    if (response.data.message) {
                        setIsAltered(prevState => !prevState);
                        toast.success(`${response.data.message}`);
                        // setUsers(prevUsers => prevUsers.filter((idx) => idx !== index));
                        
                    } else {
                        toast.error(`${response.data.message}`);
                        console.log('Error:', response.data.error);
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    return (
        <div className='className="flex flex-col w-full h-full overflow-y-auto'>
            <SearchInput value={searchTerm} onChange={handleSearch}/>
            <Card className={`min-h-fit max-h-[41rem] w-full bg-opacity-10 rounded-b-none overflow-y-auto transition ease-in-out ${isLoad === true ? 'animate-pulse h-[41rem]' : ''}`}>
                <table className="w-full min-w-max table-auto text-center">
                <thead className='sticky top-0 z-10'>
                    <tr>
                    {TABLE_HEAD.map((head) => (
                        <th key={head} className="border-b border-blue-gray-100 bg-[#1E2936] p-4  bg-opacity-60">
                        <Typography
                            variant="small"
                            color="white"
                            className="font-normal leading-none opacity-70"
                        >
                            {head}
                        </Typography>
                        </th>
                    ))}
                    </tr>
                </thead>
                <tbody>
                    
                {isLoad ? (
                    <>
                    {[...Array(9)].map((_, index) => (
                        <tr key={index} className='even:bg-blue-gray-50/50 animate-pulse'>
                            <td colSpan="7" className="p-4 animate-pulse">
                                <div className="h-[2rem] rounded"></div>
                            </td>
                        </tr>
                    ))}
                    </>
                ) : (
                    currentUsers.map(({ _id, firstName, lastName, email, role, isActive, profileImage }, index) => {
                        const avatarUrl = `https://nexus-portal.s3.amazonaws.com/profileImages/${profileImage}`
                        const userId = _id
                        const globalIndex = indexOfFirstUser + index;
                        return (
                            <tr key={_id} className={` ${isActive === false ? `bg-red-500 bg-opacity-40` : 'even:bg-blue-gray-50/50'}`}>
                                <td className="p-4">
                                    <Avatar src={profileImage ? avatarUrl : 'https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png'} alt="avatar"
                                        size="sm"
                                        className={`border-2  ${isActive === true ? 'border-blue-500' : 'border-red-500'}`} />
                                </td>
                                <td className="p-4">
                                    {isEditable === globalIndex ?
                                        <div className='flex'>
                                            <input
                                                className='mt-1 p-2 w-[50%] mx-1 bg-gray-50 bg-opacity-50 border border-gray-600 rounded-md text-white'
                                                defaultValue={firstName}
                                                onChange={e => setEditCredentials({ ...editCredentials, firstName: e.target.value })} />
                                            <input
                                                className='mt-1 p-2 w-[50%] mx-1 bg-gray-50 bg-opacity-50 border border-gray-600 rounded-md text-white'
                                                defaultValue={lastName}
                                                onChange={e => setEditCredentials({ ...editCredentials, lastName: e.target.value })} />
                                        </div>
                                        :
                                        <Typography variant="small" color="white" className="font-normal">
                                            {firstName} {lastName}
                                        </Typography>}
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="white" className="font-normal">
                                        {email}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="white" className="font-normal w-[5rem]">
                                        {isActive === true ? 'Active' : 'Disabled'}
                                    </Typography>
                                </td>
                                <td className="p-4 w-28">
                                    {isEditable === globalIndex ?
                                        <select
                                            id="role"
                                            className="bg-gray-50 p-1 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            required
                                            defaultValue={role}
                                            onChange={e => setEditCredentials({ ...editCredentials, role: e.target.value })}
                                        >
                                            <option disabled >Select Role</option>
                                            <option value="User">User</option>
                                            <option value="Admin">Admin</option>
                                        </select>
                                        :
                                        <Typography variant="small" color="white" className="font-normal">
                                            {role}
                                        </Typography>
                                    }
                                </td>
                                <td className="p-4  flex flex-wrap items-center justify-center">
                                    {isEditable === globalIndex ?
                                        userPermissions.map(({ _id, department }, index) => {
                                            const userPermission = users[globalIndex].permissions.find(p => p.department === department);
                                            const accessibility = userPermission ? userPermission.accessibility : false;
                                            const departmentId = _id;
                                            return (
                                                <Chip  
                                                    key={index}
                                                    value={department}
                                                    className={`cursor-pointer rounded-full px-2 m-1 py-1 font-medium capitalize tracking-wide ${accessibility ? 'bg-blue-600' : 'bg-red-600'}`}
                                                    onClick={() => handleUserPermissions( userId, department, departmentId, accessibility)}
                                                />
                                            );
                                        })
                                        :
                                        users[globalIndex].permissions.map(({ department }, index) => (
                                            <Chip  
                                            key={index}
                                            value={department}
                                            variant='gradient'
                                            color='blue'
                                            //className="rounded-full px-2 m-1 py-1 font-medium bg-blue-600 capitalize tracking-wide"
                                            />
                                        ))
                                    }
                                </td>
                                <td className="p-4 text-white">
                                    {isEditable === globalIndex ?
                                        <div>
                                            <button onClick={() => handleEditUser(_id, role, firstName, lastName)} className='bg-green-500 rounded-md p-1 px-2 mx-1 hover:bg-green-700'>Save</button>
                                            <button onClick={() => toggleEdit(globalIndex)} className='bg-red-500 rounded-md p-1 px-2 mx-1 hover:bg-red-700'>Cancel</button>
                                        </div>
                                        :
                                        <div>
                                            <button onClick={() => toggleEdit(globalIndex)} disabled={!isActive} className={`rounded-md p-1 mx-1 ${isActive === false ? `bg-gray-500 cursor-not-allowed` :
                                                'bg-green-500 hover:bg-green-700'}`}><HiPencilAlt className='h-5 w-5'/></button>
                                            <button onClick={() => handleToggleUserStatus(isActive, _id, firstName, lastName)} className={` rounded-md p-1 mx-1  ${isActive === true ? 'bg-orange-500 hover:bg-orange-700' :
                                                'bg-blue-500 hover:bg-blue-700'}`}>
                                                {isActive === true ? <FaUserAltSlash className='h-5 w-5'/> : <FaUserCheck className='h-5 w-5'/>}
                                            </button>
                                            <button onClick={() => handleDeleteUser(_id, firstName, lastName)} className='bg-red-500 rounded-md p-1 mx-1 hover:bg-red-700 shadow-xl'><HiTrash className="h-5 w-5"/></button>
                                        </div>
                                    }
                                </td>
                            </tr>
                        );
                    })
                )}

                </tbody>
                </table>
            </Card>
            <div className="flex flex-row items-center justify-center h-fit w-full p-1 bg-blue-100 rounded-b-md bg-opacity-80">
                <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={Math.ceil(users.length / usersPerPage)}/>
            </div>
        </div>
    );
}