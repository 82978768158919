import React from "react";
import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

export function PaginationV2({ totalItems, itemsPerPage, activePage, onPageChange }) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const maxVisiblePages = 5;

  const getItemProps = (index) => ({
    variant: activePage === index ? "filled" : "text",
    color: "gray",
    onClick: () => onPageChange(index),
    className: "rounded-full",
  });

  const getVisiblePages = () => {
    let startPage = Math.max(activePage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = startPage + maxVisiblePages - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <div className="flex items-center gap-4 bg-purple-400 bg-opacity-80 p-1 rounded-md">
      <Button
        variant="text"
        className="flex items-center gap-2 rounded-full text-white"
        onClick={() => onPageChange(activePage - 1)}
        disabled={activePage === 1}
      >
        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
        Previous
      </Button>

      {activePage > Math.floor(maxVisiblePages / 2) + 1 && (
        <IconButton {...getItemProps(1)}>1</IconButton>
      )}

      {activePage > Math.floor(maxVisiblePages / 2) + 2 && (
        <span className="text-white">...</span>
      )}

      {getVisiblePages().map((index) => (
        <IconButton key={index} {...getItemProps(index)}>
          {index}
        </IconButton>
      ))}

      {activePage < totalPages - Math.floor(maxVisiblePages / 2) - 1 && (
        <span className="text-white">...</span>
      )}

      {activePage < totalPages - Math.floor(maxVisiblePages / 2) && (
        <IconButton {...getItemProps(totalPages)}>{totalPages}</IconButton>
      )}

      <Button
        variant="text"
        className="flex items-center gap-2 rounded-full text-white"
        onClick={() => onPageChange(activePage + 1)}
        disabled={activePage === totalPages}
      >
        Next
        <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
      </Button>
    </div>
  );
}