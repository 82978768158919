import axios from 'axios';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const prodListingConv = async (batchId) => {
  try {
    const response = await axios.get(`/api/extensive-prod-creation/getCreatedProd/${batchId}`);
    const responseObj = response.data;
    const productData = responseObj.productData

    const removeDuplicates = (data) => {
      const uniqueMap = new Map();
    
      data.forEach(item => {
        const key1 = `${item.sku}_${item.listingSKU}`; 
    
        if (!uniqueMap.has(key1)) {
          uniqueMap.set(key1, item); 
        }
      });
    
      return Array.from(uniqueMap.values());
    };

    const uniqueData = removeDuplicates(productData);

    const excelData = uniqueData.map((data) => ({
      "Master SKU": data.sku,
      "Listing SKU": data.listingSKU,
      "Push Inventory to Channel?": "FALSE",
      "Active": "TRUE",
      "Fulfillment Latency Days": ""
    }));

    const timeStamp = new Date()
    const formattedDate = `${timeStamp.getMonth() + 1}-${timeStamp.getDate()}-${timeStamp.getFullYear()}`

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    const excelBlob = new Blob([excelBuffer], { type: "application/octet-stream" });

    saveAs(excelBlob, `product-listing-converter_${batchId}_${formattedDate}.xlsx`);

    console.log("Excel file generated successfully.");
  } catch (error) {
    console.error("Error generating Excel file:", error);
  }
};

export default prodListingConv;
