import React, { useState } from 'react'
import axios from 'axios'
import { useUser } from '../../store/userState';

import { EyeSlashIcon, EyeIcon } from '@heroicons/react/24/solid';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import neural1 from '../../assets/404neural.webp'
import nexus from '../../assets/nexusLogo.png'
import { Spinner } from '@material-tailwind/react';

const LoginPage = () => {

    const navigate = useNavigate()
    const  {updateUser}  = useUser();
    const [userCredentials, setUserCredentials] = useState({email: '', password: ''}) 
    const [isLoad, setIsLoad] = useState(false)
    const [toggleShowPW, setToggleShowPW] = useState(false)

    const handleTogglePW = () => setToggleShowPW(prevstate => !prevstate)

    const handleLogin = (e) => {
        e.preventDefault();
        setIsLoad(true);
        
        axios.post('/api/users/auth/login', userCredentials)
            .then(response => {
                if (response.data.message) {
                    console.log("Login failed!");
                    toast.error(`Login failed! ${response.data.message}`);
                } else {
                    //console.log("login: ", response.data)
                    localStorage.setItem('jwt', response.data.token);
                    localStorage.setItem('id', response.data.user.id);                                                                                                                                                                                                                  
                    updateUser(response.data.user); 
                    //console.log('user: ', response.data.user);
                    navigate(response.data.user.role === 'Admin' ? "/adminNXS0011/dashboard" : "/home");
                }
                setIsLoad(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setIsLoad(false);
                const errorMessage = error.response?.data?.message || 'An error occurred during login.';
                toast.error(errorMessage);
            });
        };

    return (
        <>
        <ToastContainer />
        <div className='w-screen h-screen bg-[#2b1359] flex justify-center items-center flex-col gap-1  relative overflow-hidden'>
            
            <div className="w-[65%] h-[65%] flex flex-row items-center justify-center overflow-hidden shadow-xl bg-opacity-40 bg-black p-5 rounded-lg"
            style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.7), rgba(17, 24, 38, 0.6)), url(${neural1})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>
                
                <div className="w-[50%] h-full rounded-md p-1 bg-opacity-0">
                </div>
                
                <div className="w-[50%] h-full flex flex-col items-center justify-center p-1">
                    <div className='w-[15rem] h-[3rem] mb-10'>
                        < img src={nexus} className="w-full h-full" alt='NEXUS'/>
                    </div>
                    <div className='flex flex-row items-center mb-6'>
                        <p className="text-2xl font-bold text-white ">Welcome!<span className="text-lg font-bold text-gray-400"> Sign in to continue.</span></p>
                    </div>       
                    <form onSubmit={handleLogin}>
                        <div className="mb-4 w-[310px]">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-300">Email</label>
                            <input
                                id="email"
                                className="mt-1 p-2 w-full bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white outline-none"
                                type="email"
                                onChange={e => setUserCredentials ({...userCredentials, email: e.target.value})}
                            />
                        </div>

                        <div className="mb-4 relative">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-300">
                                Password
                            </label>
                            <div className="relative">
                                <input
                                    id="password"
                                    className="mt-1 p-2 w-full bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white outline-none pr-10" 
                                    name="password"
                                    type={toggleShowPW ? 'text' : 'password'}
                                    onChange={e => setUserCredentials({...userCredentials, password: e.target.value})}
                                />
                                {!toggleShowPW ? 
                                <EyeIcon onClick={handleTogglePW} className="h-5 w-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer" /> 
                                : 
                                <EyeSlashIcon onClick={handleTogglePW} className="h-5 w-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer" />
                                }
                            </div>
                        </div>


                        <div className="flex justify-between">
                            <Link to="/password-recover"><p className='text-sm text-gray-300 hover:text-blue-400 hover:underline cursor-pointer'>Forgot password?</p></Link>
                            <button
                                className="bg-[#2271ef] text-white p-1 px-4 w-fit font-bold rounded-md hover:bg-opacity-80"
                                type="submit"
                            >
                                {isLoad ? (
                                    <div className="flex gap-1 items-center">
                                        <span className="ml-1">Signing in</span>
                                        <Spinner className='h-5 w-5' color='indigo'/>
                                    </div>
                                ) : (
                                    'Sign in'
                                )}
                            </button>
                        </div>
                        <p className='text-white text-sm mt-5'>By signing in you agree to Nexus's 
                        <a className='text-blue-400 ml-1' target='_blank' href='https://docs.google.com/document/d/1eebN7Q0weU2d_InjqjXLaUMwzS_0vJ93oY5EAjM49JM/edit?tab=t.0#heading=h.pu589vg78r4r'>
                        Privacy Policy 
                        </a>
                        </p>

                    </form>
                </div>
                

            </div>

        </div>
        </>                                                                                    

    )
}

export default LoginPage