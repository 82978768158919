import React from 'react'
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'

const ProdCreationImportTemplate = () => {

    const handleDownload = () => {
        const filePath = `${process.env.PUBLIC_URL}/Extensiv Prod Creation - SKU Import Template.xlsx`;

        const link = document.createElement('a');
        link.href = filePath;
        link.download = 'Extensiv Prod Creation - SKU Import Template.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <>
            <button
                onClick={handleDownload}
                className="inline-flex items-center text-base font-medium text-blue-500 hover:text-blue-400 hover:underline">
                <ArrowDownTrayIcon className="w-5 h-5 mr-2 -ml-1" />
                Download template
            </button>
        </>

    )
}

export default ProdCreationImportTemplate
