import React, { useState } from 'react'
import axios from 'axios'

import { ArrowLongLeftIcon, ArrowPathIcon, EyeSlashIcon, EyeIcon  } from '@heroicons/react/24/solid';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import neural1 from '../../assets/404neural.webp'
import nexus from '../../assets/nexusLogo.png'

const ResetPW = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');  // Extract token from URL
    const navigate = useNavigate();
    const [userCredentials, setUserCredentials] = useState({ newPassword: '', confirmNewPassword: ''});
    const [isLoading, setIsLoading] = useState(false);
    const [toggleShowPw, setToggleShowPw] = useState({
        newPassword: false,
        confirmPassword: false
    })

    const handleTogglePw = (field) => {
        setToggleShowPw(prevState => ({
            ...prevState,
            [field]: !prevState[field]
        }))
    }

    const resetPassword = async () => {
        if (!token) {
            toast.error("Invalid reset token.");
            return;
        }

        if (userCredentials.newPassword !== userCredentials.confirmNewPassword) {
            toast.error("Passwords do not match!");
            return;
        }

        setIsLoading(true);

        const userCreds = {
            token: token,
            newPassword: userCredentials.newPassword,
            confirmNewPassword: userCredentials.confirmNewPassword
        }
        axios
        .post('/api/users/auth/reset-password', userCreds) 
        .then((response) => {
            toast.success(response.data.message);
            setTimeout(() => navigate('/login'), 2000);  
        })
        .catch((error) => {
            console.error('Error:', error);
            const errorMessage = error.response?.data?.message || 'An error occurred.';
            toast.error(errorMessage);
        })
        .finally(() => setIsLoading(false));
    };

    return (
        <>
        <ToastContainer />
        <div className='w-screen h-screen bg-[#2b1359] flex justify-center items-center flex-col gap-1  relative overflow-hidden'>
            
            <div className="w-[55%] h-[55%] flex flex-row items-center justify-center overflow-hidden shadow-xl bg-opacity-40 bg-black p-5 rounded-lg"
            style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.7), rgba(17, 24, 38, 0.6)), url(${neural1})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>
            
                <div className="w-full h-full flex flex-col justify-around items-center p-1">
                    <div className='flex flex-row justify-between w-full'>
                            <p className="text-2xl font-semibold text-white ">Password Recovery</p>
                        <p></p>
                    </div>
                
                    <div className='w-[15rem] h-[3rem] my-8'>
                        < img src={nexus} className="w-full h-full" alt='NEXUS'/>
                    </div>

                    <form onSubmit={(e) => {
                        e.preventDefault();  
                        resetPassword();
                    }}>
                        <div className="mb-4 relative w-[310px]">
                            <label htmlFor="password" className="block text-sm font-medium text-gray-300">New password</label>
                            <div className='relative'>
                                <input
                                id="newPassword"
                                className="mt-1 p-2 w-full bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white outline-none"
                                name="password"
                                type={toggleShowPw.newPassword ? 'text' : 'password'}
                                onChange={e => setUserCredentials ({...userCredentials, newPassword: e.target.value})}
                                />
                                {toggleShowPw.newPassword ? 
                                    <EyeSlashIcon onClick={() => handleTogglePw('newPassword')} className='h-5 w-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'/> :
                                    <EyeIcon onClick={() => handleTogglePw('newPassword')} className='h-5 w-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'/>
                                }
                            </div>

                        </div>

                        <div className="mb-4 relative">
                            <label  className="block text-sm font-medium text-gray-300">Confirm new password</label>
                            <div className="relative">
                                <input
                                id="confirmNewPassword"
                                className="mt-1 p-2 w-full bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white outline-none"
                                name="password"
                                type={toggleShowPw.confirmPassword ? 'text' : 'password'}
                                onChange={e => setUserCredentials ({...userCredentials, confirmNewPassword: e.target.value})}
                                />
                                {toggleShowPw.confirmPassword ? 
                                    <EyeSlashIcon onClick={() => handleTogglePw('confirmPassword')} className='h-5 w-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'/> :
                                    <EyeIcon onClick={() => handleTogglePw('confirmPassword')} className='h-5 w-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'/>
                                }
                            </div>

                        </div>

                        <div className="flex justify-between">
                            <button
                            className="bg-[#2271ef] text-white px-4 p-1 w-fit font-bold rounded-md hover:bg-opacity-80"
                            type="submit"
                            >
                                {isLoading ? (
                                    <div className="flex gap-1 items-center">                     
                                    <span className="ml-1">Confirm</span>
                                    <ArrowPathIcon className="h-5 w-5 animate-spin" />
                                    </div>
                                ) : (
                                    'Confirm'
                                )}
                            </button>
                        </div>
                    </form>

                    <div className='flex flex-row justify-between w-full mt-10'>
                        <p></p>
                        <Link to="/login">
                            <button className="text-md font-bold text-gray-400 hover:text-gray-200 flex items-center">
                                <ArrowLongLeftIcon className="h-5 w-5 mr-2" />
                                Back to login
                            </button>
                        </Link>
                    </div>
                </div>

            </div>

        </div>
        </>

    )
}

export default ResetPW