const XLSX = require('xlsx');

const ExcelParse = async (file, options = { includeHeaders: true }) => {
    const workbook = XLSX.read(file, { type: 'buffer' });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    
    // Convert the worksheet to JSON format as a 2D array and provide default values
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, defval: "" });
    
    if (jsonData.length === 0) return [];
    
    const headers = options.includeHeaders ? jsonData[0] : jsonData[0].map((_, i) => `Column${i + 1}`);
    const dataRows = options.includeHeaders ? jsonData.slice(1) : jsonData;
    
    // Convert each row into an object using headers as keys
    const result = dataRows.map(row => {
        let rowData = {};
        headers.forEach((header, index) => {
            rowData[header] = row[index] || "";
        });
        return rowData;
    });
    
    return result;
};

export default ExcelParse;