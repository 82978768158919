import axios from 'axios'
import getLastUserAct from './calcLastUserActivity'

export const warehouseUserAct = async () => {
    try {
        const exntensivResponse = await axios.get('/api/extensive-prod-creation-requests/getBatches');
        const refAuditsResponse = await axios.get('/api/refund-audits/all-batches');

        const userData = refAuditsResponse.data.result;
        const userData2 = exntensivResponse.data;

        const combinedData = [...userData, ...userData2];
        const sortedData = combinedData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        const mostRecentActivity = sortedData[0]?.updatedAt;

        const calcWHTimeAgo = getLastUserAct(mostRecentActivity);

        return calcWHTimeAgo;
    } catch (error) {
        console.error('Failed to fetch counter value:', error);
        return 0;
    }
};

export const pricingUserAct = async () => {
    try {
        const response = await axios.get('/api/coupon-promotions/all-batches')
        const userData = response.data.result

        const filteredBatches = userData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        const mostRecentActivity = filteredBatches[0]?.updatedAt
        const calcPrcTimeAgo = getLastUserAct(mostRecentActivity)
        console.log('Pricing Time ago:', calcPrcTimeAgo)

        return calcPrcTimeAgo

    } catch (error) {
        console.error('Failed to fetch counter value:', error)
        return 0 
    }
}

export const listingsUserAct = async () => {
    try {
        // const response = await axios.get('/api/amz-cases/get')
        // const userData = response.data.result
        const response = await axios.get('/api/scraper-requests/getRequests')
        const userData = response.data.requests

        const filteredBatches = userData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        const mostRecentActivity = filteredBatches[0]?.updatedAt
        const calcListingsTimeAgo = getLastUserAct(mostRecentActivity)
        console.log('Listings Time ago:', calcListingsTimeAgo)

        return calcListingsTimeAgo

    } catch (error) {
        console.error('Failed to fetch counter value:', error)
        return 0 
    }
}