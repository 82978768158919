import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { CheckCircleIcon, ClockIcon, TrashIcon } from "@heroicons/react/24/solid";
import { Button, Card, Progress, Tooltip, Typography } from "@material-tailwind/react";
import { TbLoader3 } from "react-icons/tb";
import { PaginationV2 } from "../../../../Components/PaginationV2";
import { ProductCreationModal } from "./ProdCreationModal";
import MultDownloadBtn from './MultDownloadBtn';

const TABLE_HEAD = ["Batch ID", "Uploaded by", "Brand", "Sub Brand", "Status", "Actions"];

export function ProdCreationTable() {

  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isAltered, setIsAltered] = useState(false);

  products.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

  const itemsPerPage = 10; 
  const paginatedProducts = products.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  useEffect(() => {
    axios.get('/api/extensive-prod-creation-requests/getBatches')
        .then(response => {
            if (response.data.message) {
                console.log("Cannot get batches")
            } else {
              //console.log('Products:', response.data); 
              setProducts(response.data);
            } 
        })
        .catch(error => {
            console.error('Error:', error)
        })
  },[isAltered])

  useEffect(() => {
      const toggleInterval = setInterval(() => {
          setIsAltered(prevState => !prevState)
      }, 2000) 
      return () => {
          clearInterval(toggleInterval)
      }
  }, [])

  const handleAlterState = () => {
    setIsAltered(prevstate => !prevstate);
  };

  const handleDeleteBatch =(batchId) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete this batch: ${batchId}?`)

    if (isConfirmed) {
      axios.delete(`/api/extensive-prod-creation-requests/delete/${batchId}`)
        .then(response => {
            if (response.data.message) {
                toast.success(`${response.data.message}`)
                setIsAltered(prevState => !prevState)
                axios.delete(`/api/extensive-prod-creation/delete/${batchId}`)
                .then(response => {console.log(response.data.result)})
            } else {
                toast.error(`${response.data.message}`)
                console.log('Error:', response.data.error)
            }
        })
        .catch(error => {
            console.error('Error:', error)
        }) 
    }
  }

  return (
    <div className="h-[90%] gap-2 w-full flex flex-col justify-center items-center">
      <div className='flex justify-between w-[99%] text-white'>
        <ProductCreationModal onAlterState={handleAlterState}/> 
        <p className='font-semibold'>Total Batches: <span className='text-blue-500'>{products.length}</span></p>
      </div>
      
      <Card className="h-[37rem] w-full bg-black bg-opacity-70 overflow-y-auto overflow-x-auto">
        <table className="w-full min-w-max table-auto text-center">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th key={head} className="p-4 pt-5 sticky top-0 z-10 bg-black bg-opacity-70">
                  <Typography
                    variant="small"
                    className="font-bold leading-none text-purple-300"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
          {products.length <= 0 ?
            <>
              {[...Array(9)].map((_, index) => (
                <tr key={index} className='border-y bg-gray-600 border-none animate-pulse'>
                    <td colSpan="12" className="p-4">
                        <div className="h-[1.8rem] rounded"></div>
                    </td>
                </tr>
              ))}
            </>
            :
            (paginatedProducts.map((item) => {
              const date = new Date(item.createdAt);
              const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
              const options = { month: 'long', day: 'numeric', year: 'numeric' };
              const formattedDate = date.toLocaleDateString('en-US', options);
              const formattedDateTime = `${formattedDate} ${formattedTime}`;

              const brands = item.importData.map(product => product.brand);
              const subBrands = item.importData.map(product => product.subBrand);
            
              const getDisplayAndRemaining = (arr) => {
                const uniqueItems = [...new Set(arr.filter(item => item))]; 
                return {
                  display: uniqueItems.slice(0, 2),
                  remaining: uniqueItems.slice(2),
                };
              };
              // Get display and remaining values for each field
              const { display: displayBrands, remaining: remainingBrands } = getDisplayAndRemaining(brands);
              const { display: displaySubBrands, remaining: remainingSubBrands } = getDisplayAndRemaining(subBrands);

              return (
                <tr key={item.batchId} className="hover:bg-purple-300">
                  <td className="p-4">
                    <Typography variant="small" color="white" className="font-bold flex items-center justify-center">
                      {item.batchId}
                      <Tooltip content={formattedDateTime}>
                        <ClockIcon className='h-5 w-5 ml-1' />
                      </Tooltip>
                    </Typography>
                  </td>
                  <td className="p-4">
                    <Typography variant="small" className="font-normal text-white">{item.addedBy}</Typography>
                  </td>

                  {/* Brands */}                
                  <td className="">
                    <Typography variant="small" className="font-normal text-white justify-center">
                      {displayBrands.map((brand, index) => (
                        <span className='m-2 bg-purple-500 text-white p-2 rounded-md cursor-default' key={index}>{brand}</span>
                      ))}
                      {remainingBrands.length > 0 && (
                        <Tooltip content={remainingBrands.map((brand, index) => <div key={index}>{brand}</div>)}>
                          <span className='m-2 cursor-pointer'>{`+ ${remainingBrands.length} more`}</span>
                        </Tooltip>
                      )}
                    </Typography>
                  </td>

                  {/* SubBrands */}
                  <td className="p-4">
                    <Typography variant="small" className="font-normal text-white">
                      {displaySubBrands.map((subBrand, index) => (
                        <span className='m-2 bg-blue-500 text-white p-2 rounded-md cursor-default' key={index}>{subBrand}</span>
                      ))}
                      {remainingSubBrands.length > 0 && (
                        <Tooltip content={remainingSubBrands.map((subBrand, index) => <div key={index}>{subBrand}</div>)}>
                          <span className='m-2 cursor-pointer'>{`+ ${remainingSubBrands.length} more`}</span>
                        </Tooltip>
                      )}
                    </Typography>
                  </td>

                  <td className="p-4">
                    <div className="mb-2 flex items-center justify-between gap-4">
                      <Typography color="white" className='text-sm'>
                      {item.progress === 100 ? 
                        <div className='flex items-center'>Completed<CheckCircleIcon className='ml-1 h-5 w-5 text-green-500'/></div> 
                        : 
                        <div className='flex items-center animate-pulse'>In progress<TbLoader3 className='ml-1 h-5 w-5 animate-spin'/></div>
                      }
                      </Typography>
                      <Typography color="white" className='text-sm'>
                      {item.progress}%
                      </Typography>
                    </div>
                    <Progress value={item.progress} color= {item.progress === 100 ? 'green' : 'orange'}/>
                  </td>

                  <td className="p-4">
                    <div className="flex items-center justify-center gap-2">
                      {item.progress === 100 ?
                        <>
                          <MultDownloadBtn batchId={item.batchId}/> 
                          <Button onClick={() => handleDeleteBatch(item.batchId)} variant="gradient" color="red" size="sm"> 
                            <TrashIcon className="h-4 w-4" />
                          </Button>
                        </>
                          :
                        <>
                          <Button onClick={() => handleDeleteBatch(item.batchId)} variant="gradient" color="red" size="sm"> 
                            <TrashIcon className="h-4 w-4" />
                          </Button>
                        </>
                      }
                    </div>
                  </td>
                </tr>
              );
            }))
          }
          </tbody>
        </table>
      </Card>
      {products.length > 5 && (
      <PaginationV2
        totalItems={products.length}
        itemsPerPage={itemsPerPage}
        activePage={currentPage}
        onPageChange={setCurrentPage}
      />
    )}
    </div>

  );
}