import axios from 'axios';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const prodCreation = async (batchId) => {
  try {
    const response = await axios.get(`/api/extensive-prod-creation/getCreatedProd/${batchId}`);
    const responseObj = response.data;
    const productData = responseObj.productData
    
    const removeDuplicates = (data) => {
      const uniqueMap = new Map();
    
      data.forEach(item => {
        const key1 = `${item.sku}_${item.upc}`; 
    
        if (!uniqueMap.has(key1)) {
          uniqueMap.set(key1, item); 
        }
      });
    
      return Array.from(uniqueMap.values());
    };

    const uniqueData = removeDuplicates(productData);

    //console.log('uniqueData', uniqueData)

    const excelData = uniqueData.map((data) => ({
      "Master SKU": data.sku,
      "Virtual SKU": "",
      "Name": data.title || "", 
      "UPC": data.upc || "", 
      "Attribute Group {Attribute}": "",
      "Description": "",
      "Image URLs (semicolon-delimited)": "",
      "Brand": data.brand || "",
      "Categories (pipe-delimited)": "",
      "Labels (pipe-delimited)": `${data.subBrand ? data.subBrand : data.brand || ""}|${data.segment || ""}|${data.category || ""}`,
      "M.A.P.": data.mapMsrp || "",
      "Shipping Cost": "",
      "Pounds…": data.pounds || "",
      "...plus Ounces": "",
      "Height (in.)": data.height || "",
      "Width (in.)": data.width || "",
      "Length (in.)": data.length || "",
      "Allow Backorders": "",
      "Hazmat": "FALSE",
      "Ships in Own Box": "",
      "Active": "TRUE",
      "Vendor Cost": data.cost || "",
      "With Barcode": "TRUE",
      "Buyer (E-Mail)": "",
      "MPN": "",
      "Gift Card": "",
      "Digital": ""
    }));

    const timeStamp = new Date()
    const formattedDate = `${timeStamp.getMonth() + 1}-${timeStamp.getDate()}-${timeStamp.getFullYear()}`

    const workbook = XLSX.utils.book_new();

    // Create and append an empty "Instructions" worksheet
    const instructionSheet = XLSX.utils.aoa_to_sheet([[""]]);
    XLSX.utils.book_append_sheet(workbook, instructionSheet, "Instructions");

    // Create and append "Core Products" sheet with actual data
    const coreProductsSheet = XLSX.utils.json_to_sheet(excelData);
    XLSX.utils.book_append_sheet(workbook, coreProductsSheet, "Core Products");

    // Create and append an empty "Bundles & Kits" worksheet
    const bundlesKitsSheet = XLSX.utils.aoa_to_sheet([[""]]);
    XLSX.utils.book_append_sheet(workbook, bundlesKitsSheet, "Bundles & Kits");

    // Create and append an empty "Customs Info" worksheet
    const customsInfoSheet = XLSX.utils.aoa_to_sheet([[""]]);
    XLSX.utils.book_append_sheet(workbook, customsInfoSheet, "Customs Info");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], { type: "application/octet-stream" });

    saveAs(excelBlob, `product-creation_${batchId}_${formattedDate}.xlsx`);

    console.log("Excel file generated successfully.");
  } catch (error) {
    console.error("Error generating Excel file:", error);
  }
};

export default prodCreation;
