import React, { useState, useEffect } from 'react'

import {
  Popover,
  PopoverHandler,
  PopoverContent,
  Button,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";

import { FaDownload } from "react-icons/fa6";
import { BiLoaderCircle } from 'react-icons/bi';
import prodCreation from '../../../../middlewares/Warehouse/xtnsv-prodcreation';
import skuConverter from '../../../../middlewares/Warehouse/xtnsv-sku-conv';
import prodListingConv from '../../../../middlewares/Warehouse/xtnsv-prodlist-conv';

export default function MultDownloadBtn({batchId}) {

  const [downloadingBatch, setDownloadingBatch] = useState({});

  const generateProdCreationExport = async () => {
    setDownloadingBatch(prev => ({
      ...prev,
      [batchId]: { ...(prev[batchId] || {}), prodCreation: true }
    }));
    await prodCreation(batchId);
    setDownloadingBatch(prev => ({
      ...prev,
      [batchId]: { ...(prev[batchId] || {}), prodCreation: false }
    }));
  };

  const generateskuConv = async () => {
    setDownloadingBatch(prev => ({
      ...prev,
      [batchId]: { ...(prev[batchId] || {}), skuConv: true }
    }));
    await skuConverter(batchId);
    setDownloadingBatch(prev => ({
      ...prev,
      [batchId]: { ...(prev[batchId] || {}), skuConv: false }
    }));
  };

  const generateProdListConv = async () => {
    setDownloadingBatch(prev => ({
      ...prev,
      [batchId]: { ...(prev[batchId] || {}), prodListConv: true }
    }));
    await prodListingConv(batchId);
    setDownloadingBatch(prev => ({
      ...prev,
      [batchId]: { ...(prev[batchId] || {}), prodListConv: false }
    }));
  };

  return (
    <Popover placement="bottom-end">
      <PopoverHandler>
        <Button variant="gradient" color="blue" size="sm"><FaDownload className="h-4 w-4"/></Button>
      </PopoverHandler>
      <PopoverContent className="w-72">
        <div className="mb-4 flex items-center gap-4 border-b border-blue-gray-50 pb-4">
          <div>
            <Typography variant="h6" color="blue-gray">
              Export File Converters
            </Typography>
            <Typography
              variant="small"
              color="gray"
              className="font-medium text-blue-gray-500"
            >
              Batch ID: {batchId}
            </Typography>
          </div>
        </div>
        <List className="p-0">
          <button onClick={generateProdCreationExport} className="text-initial font-medium text-blue-500">
            <ListItem>
              <ListItemPrefix>
                {downloadingBatch[batchId]?.prodCreation ? (
                  <BiLoaderCircle className='h-3 w-3 animate-spin text-blue-500' />
                  ) : (
                  <FaDownload className="h-3 w-3"/>
                )}
              </ListItemPrefix>
                Product Creation
            </ListItem>
          </button>
          <button onClick={generateskuConv} className="text-initial font-medium text-blue-500">
            <ListItem>
              <ListItemPrefix>
                {downloadingBatch[batchId]?.skuConv ? (
                  <BiLoaderCircle className='h-3 w-3 animate-spin text-blue-500' />
                  ) : (
                  <FaDownload className="h-3 w-3"/>
                )}
              </ListItemPrefix>
                Vendor SKU 
            </ListItem>
          </button>
          <button onClick={generateProdListConv} className="text-initial font-medium text-blue-500">
            <ListItem>
              <ListItemPrefix>
                {downloadingBatch[batchId]?.prodListConv ? (
                  <BiLoaderCircle className='h-3 w-3 animate-spin text-blue-500' />
                  ) : (
                  <FaDownload className="h-3 w-3"/>
                )}
              </ListItemPrefix>
                Product Listing 
            </ListItem>
          </button>
        </List>
      </PopoverContent>
    </Popover>
  );
}
