import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import ExcelParse from "../../../../middlewares/GlobalUtils/excelReader";

import { Button, Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import generateTimestamp from "../../../../middlewares/GlobalUtils/generateTimeStamp";
import { useUser } from "../../../../store/userState";
import ProdCreationImportTemplate from "./ProdCreationimportTemplate";

export function ProductCreationModal({onAlterState}) {
  const { user } = useUser()

  const [open, setOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false)
  const [productData, setProductData] = useState({})

  const handleOpen = () => setOpen(!open);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
  
      reader.onload = async (e) => {
        const arrayBuffer = e.target.result;
        const parsedData = await ExcelParse(arrayBuffer);
  
        if (!parsedData.length) {
          console.warn("Parsed data is empty!");
          return;
        }
  
        const formattedData = parsedData
          .map((item) => ({
            sku: item["SKU"]?.trim() || "",
            domain: item["DOMAIN"]?.trim() || "",
            listingSKU: item["LISTING SKU"]?.trim() || "",
            upc: (item["UPC"] !== null && item["UPC"] !== undefined) ? String(item["UPC"]).trim() : "",
            brand: item["BRAND"]?.trim() || "",
            mapMsrp: item["MAP/MSRP"]?.toString().trim() || "",
            cost: item["COST"]?.toString().trim() || "",
            subBrand: item["SUB-BRAND"]?.trim() || "",
            segment: item["SEGMENT"]?.trim() || "",
            category: item["CATEGORY"]?.trim() || "",
          }))
          .filter((item) => item.sku !== "");
  
        const removeDuplicates = (data) => {
          const uniqueMap = new Map();
  
          data.forEach((item) => {
            const key1 = `${item.sku}_${item.listingSKU}_${item.upc}`;
            const key2 = `${item.sku}_${item.upc}`;
  
            // If neither key1 nor key2 exists in the map, add the item
            if (!uniqueMap.has(key1) && !uniqueMap.has(key2)) {
              uniqueMap.set(key1, item);
            }
          });
  
          return Array.from(uniqueMap.values());
        };
  
        const uniqueData = removeDuplicates(formattedData);
  
        setProductData(uniqueData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleGetDimension = async (batchNum) => {

    const importedSkus = { batchId: batchNum,imports: productData }

    //console.log("Backend importdata: ", importedSkus)
    setBtnLoading(false)

    return axios.post('/api/extensiv-reports/dimensions', importedSkus)
      .then(({ data, status }) => {
        console.log('Response:', data);
        setProductData(data)
        const message = Array.isArray(data) && data.length > 0
          ? data[0]?.message || "Request Successful"
          : "Request Successful";

        if (status === 200) {
          toast.success(message);
          setOpen(false);
          return data;  
        } else {
          toast.error(message);
          return null;
        }
      })
      .catch(({ response }) => {
        console.error('Error Response:', response);
        const errorMessage = response?.data?.message ||
          `Request failed with status ${response?.status || "unknown"}. Please try again.`;
        toast.error(errorMessage);
        console.error('Error message: ', errorMessage)
        return null;
      });
  };

  const productCreation = () => {
    const batchData = {
      batchId: generateTimestamp(),
      importData: productData,  
      addedBy: user.email
    };

    setBtnLoading(true)

    //console.log('batchData:', batchData);
  
    axios.post('/api/extensive-prod-creation-requests/generateBatch', batchData)
      .then(({ data, status }) => {

        const message = Array.isArray(data) && data.length > 0
          ? data[0]?.message || "Product Created"
          : "Product Created";

        if (status === 200) {
          handleGetDimension(batchData.batchId)
          toast.success(message)
          setOpen(false)
          setProductData([])
          onAlterState()
        } else {
          toast.error(message);
        }
      })
      .catch(({ response }) => {
        console.error('Error Response:', response);
        const errorMessage = response?.data?.message ||
          `Request failed with status ${response?.status || "unknown"}. Please try again.`;
        toast.error(errorMessage);
      });
  };

  return (
    <>
      <Button onClick={handleOpen} variant="gradient" color="purple">
        Submit
      </Button>
      <Dialog
        open={open}
        handler={handleOpen}
        animate={{
          mount: { scale: 1, y: 0 },
          unmount: { scale: 0.9, y: -100 },
        }}
        className="bg-purple-400"
      >
        <DialogHeader className="text-white">Import Data</DialogHeader>
        <DialogBody className="text-white">
        <div className='p-4 w-full h-fit rounded-xl flex flex-col items-center'>
          
          <div className='flex gap-4 h-full w-full items-center justify-center'>
              <div className="w-full py-5 rounded-2xl border border-gray-300 gap-3 grid border-dashed">
                <div className="flex flex-col justify-center items-center gap-2">
                  <h4 className="text-center text-white text-sm font-medium leading-snug">Import file here</h4>
                  <div className="flex items-center justify-center">
                      <input type="file" onChange={handleFileChange}/>
                  </div>
                    <ProdCreationImportTemplate/>
                </div>
              </div> 
          </div>
        </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="white"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="black" loading={btnLoading} onClick={productCreation}>
            <span>
              Confirm
            </span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}