import React from 'react'
import { ToastContainer } from 'react-toastify'

import neural2 from '../../../../assets/neural-networks.webp'
import xtensiv from '../../../../assets/Extensiv.webp'
import FloatBar from '../../../../Components/FloatBar'
import SideButton from '../../../../Components/SideButton';
import { PurchaseOrderTable } from './PurchaseOrderTable'

const ExtensivToolsPOView = () => {
  return (
    <div className='w-screen h-screen bg-[#111926] flex justify-center items-center flex-col gap-1  relative overflow-hidden'
    style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.7), rgba(17, 24, 38, 0.8)), url(${neural2})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>

      <FloatBar/>
      <div className='flex flex-row gap-2 w-full h-full  '>
      <SideButton/>
      <div className='w-full h-full flex items-center flex-col p-1 pl-4'>
        <ToastContainer/>
        <div className='flex flex-col justify-center items-center gap-1'>
          <div className='h-[2rem] w-[8rem]'>
            <img className='h-full w-full' src={xtensiv}/>
          </div>
          <p className='text-2xl font-semibold text-white '>Purchase Order Converter</p>
        </div>
        
        <div className='w-full h-full p-5 pl-4 flex  justify-center m-1 overflow-y-auto overflow-x-auto'>
          <PurchaseOrderTable/>
        </div>
      </div>
      </div>
    </div>
  )
}

export default ExtensivToolsPOView