import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { ArrowLongLeftIcon, ArrowPathIcon, ArrowUturnLeftIcon, CubeTransparentIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Spinner } from '@material-tailwind/react';
import 'react-toastify/dist/ReactToastify.css';

import neural1 from '../../assets/404neural.webp';
import nexus from '../../assets/nexusLogo.png'

const RequestChangeCreds = () => {
    const [isLoad, setIsLoad] = useState(false);
    const [userCredentials, setUserCredentials] = useState({ email: '' });
    const [countdown, setCountdown] = useState(() => {
        const savedCountdown = localStorage.getItem('countdown');
        return savedCountdown ? parseInt(savedCountdown, 10) : 0;
    });

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setTimeout(() => {
                setCountdown(countdown - 1);
                localStorage.setItem('countdown', countdown - 1);
            }, 1000);
        } else {
            localStorage.removeItem('countdown');
        }
        return () => clearTimeout(timer);
    }, [countdown]);

    const reqResetPassword = (e) => {
        e.preventDefault();
        setIsLoad(true);
        axios
            .post('/api/users/auth/request-password-reset', { email: userCredentials.email })
            .then((response) => {
                toast.success(response.data.message);
                setIsLoad(false);
                setCountdown(30);
                localStorage.setItem('countdown', 30);
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsLoad(false);
                const errorMessage = error.response?.data?.message || 'An error occurred during password reset.';
                toast.error(errorMessage);
            });
    };

    return (
        <>
            <ToastContainer />
        <div className='w-screen h-screen bg-[#2b1359] flex justify-center items-center flex-col gap-1  relative overflow-hidden'>
            
            <div className="w-[55%] h-[55%] flex flex-row items-center justify-center overflow-hidden shadow-xl bg-opacity-40 bg-black p-5 rounded-lg"
            style={{backgroundImage: `linear-gradient(rgba(17, 24, 38, 0.7), rgba(17, 24, 38, 0.6)), url(${neural1})`, backgroundSize: 'cover', backgroundPosition: 'center', backdropFilter: 'blur(8px)' }}>

                <div className="w-full h-full flex flex-col justify-around items-center p-1">
                    <div className='flex flex-row justify-between w-full'>
                            <p className="text-2xl font-semibold text-white ">Password Recovery</p>
                        <p></p>
                    </div>
                
                    <div className='w-[15rem] h-[3rem] my-8'>
                        < img src={nexus} className="w-full h-full" alt='NEXUS'/>
                    </div>

                        <form onSubmit={reqResetPassword}>
                            <p className='text-gray-200 text-sm mb-4'>Enter email to request a password recovery link.</p>
                            <div className="mb-4 w-[310px]">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-300">
                                    Email
                                </label>
                                <input
                                    id="email"
                                    className="mt-1 p-2 w-full bg-gray-700 bg-opacity-50 border border-gray-600 rounded-md text-white outline-none"
                                    type="email"
                                    value={userCredentials.email} // Bind input value
                                    onChange={(e) => setUserCredentials({ ...userCredentials, email: e.target.value })}
                                />
                            </div>

                            <div className="flex flex-row gap-2 items-center">
                                <button
                                    className={`px-4 p-1 w-fit font-bold rounded-md ${isLoad || countdown > 0 ? 'bg-gray-500' : 'bg-[#2271ef] hover:bg-opacity-80'} text-white`}
                                    type="submit"
                                    disabled={isLoad || countdown > 0} // Prevent multiple submissions
                                >
                                    {isLoad ? (
                                        <div className="flex gap-1 items-center">
                                            <span className="ml-1">Sending</span>
                                            <Spinner className="h-5 w-5" color="indigo" />
                                        </div>
                                    ) : (
                                        'Send link'
                                    )}
                                </button>
                                {countdown > 0 && <p className='text-gray-200'>Resend link in {countdown}s</p>}
                            </div>
                        </form>

                    <div className='flex flex-row justify-between w-full mt-10'>
                        <p></p>
                        <Link to="/login">
                            <button className="text-md font-bold text-gray-400 hover:text-gray-200 flex items-center">
                                <ArrowLongLeftIcon className="h-5 w-5 mr-2" />
                                Back to login
                            </button>
                        </Link>
                    </div>
                </div>
                </div>
            </div>
        </>
    );
};

export default RequestChangeCreds;